const SmoothScroll = require('smooth-scroll');

import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();
AOS.refresh();

// Smooth scroll behaviro for the menu links
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  easing: 'easeInOutCubic',
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true,
});


// Listener for applying a class to the navbar on scroll
/*
window.addEventListener('scroll', event => {
  const menu = document.getElementById('menu'); 
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if(scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if(scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});
*/



// Instafeed

window.onload = function(){
  
    fetch('https://ig.instant-tokens.com/users/be1380ac-9eae-4aa6-9ded-20cb628c5955/instagram/17841410889178172/token?userSecret=wglw3u3wt6gt1mbt2acy5')
      .then(response => response.json())
      .then(json => {
        var feed = new Instafeed({
          accessToken: json.Token,
          limit: 6,
        });
        feed.run();   
          // console.log(json.Token)
      })
      .catch((error) => {
        console.error('Error: not available', error);
    });

}



// Block Warning
const detect = document.querySelector("#detect");
const warning = document.querySelector("#block-warning > div.block-box");
const buttonBlock = document.querySelector("#block-warning .block-box .btn-accept");

let adClasses = ["ad", "ads", "adsbox", "doubleclick", "ad-placement", "ad-placeholder", "adbadge", "BannerAd"];
for(let item of adClasses){
  detect.classList.add(item);
}
let getProperty = window.getComputedStyle(detect).getPropertyValue("display");
if(!warning.classList.contains("show")){
  getProperty == "none" ? warning.classList.add("show") : warning.classList.remove("show");
}
buttonBlock.addEventListener("click", ()=>{
  warning.classList.remove("show");
});

//Hide IG Feed
const detectTwo = document.querySelector("#detect-ig");
const viewIG = document.querySelector("#our-latest .container > div.our-latest-dsk");
let adClassesTwo = ["ad", "ads", "adsbox", "doubleclick", "ad-placement", "ad-placeholder", "adbadge", "BannerAd"];
for(let items of adClassesTwo){
  detectTwo.classList.add(items);
}
let getPropertyTwo = window.getComputedStyle(detectTwo).getPropertyValue("display");
if(!viewIG.classList.contains("hide")){
  getPropertyTwo == "none" ? viewIG.classList.add("hide") : viewIG.classList.remove("hide");
}